const setting = {
  state: {
    settingType: 'workflow',
    pageType: 'default',
    beginUpdate: new Date(),
    publisherId: null,
    companyId: null,
    detailId: null,
    chkType: '',
    sub: '',
    name: '',
    subName: ''
  },
  mutations: {
    set_setting_settingType (state, data) {
      state.settingType = data
    },
    set_setting_pageType (state, data) {
      state.pageType = data
    },
    set_setting_update (state, data) {
      state.beginUpdate = data
    },
    set_setting_publisherId (state, data) {
      state.publisherId = data
    },
    set_setting_companyId (state, data) {
      state.companyId = data
    },
    set_setting_detailId (state, data) {
      state.detailId = data
    },
    set_setting_name (state, data) {
      state.name = data
    },
    set_setting_subName (state, data) {
      state.subName = data
    },

    set_setting_sub (state, data) {
      state.sub = data
    },
    set_setting_type (state, data) {
      state.chkType = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default setting
